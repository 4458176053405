/****** Pins ******/

.marker {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marker-text {
    font-size: 18px;
    color: white;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    z-index: 2;
}