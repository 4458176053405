/************* Header bar styles *************/

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* border: white 2px solid; */
    width: 100%;
    /* make z-index work priority over all others */
    z-index: 100;
    padding: 0px 5px 0px 0px;
    position: sticky;
    background: linear-gradient(
      to bottom,
      #08436d,
      #063455
    );
}

header h1 {
  padding: 0px;
  margin: 0px;
  color: white;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  text-transform: uppercase;
  letter-spacing: 0px;
}

header h1 span {
  letter-spacing: 5px;
}


.header-logo {
    height: 55px;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 5px;

}

.header-button {
    padding: 5px 20px;
    height: 50px;
    width: 100px;
    border: 2px solid white;
    background-color: #4fabec;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.header-button:hover {
    transition: 0.2s;
    transform: translateY(-3px);
    background-color: #008ef3;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    border: 2px solid #000;
}

.header-refresh {
    font-size: 30px;
    text-indent: 3px;
    height: 50px;
    width: 50px;
    margin-right: 20px;
    padding: 10px;
    background-color: #e60000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    border: 2px solid white;
    text-align: center;
    align-items: center;
    font-display: block;
    font-weight: bold;
  }


.header-refresh:hover {
    transition: 0.2s;
    font-size: 35px;
    font-weight: bold;
    transform: translateY(-3px);
    background-color: #ff0000;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    color:rgba(0, 0, 0, 1);
    cursor: pointer;
    border: 2px solid black;
}

/* Styling for small screens - Bootstrap */
@media (max-width: 991.98px) {

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        top: 0;
        width: 100%;
        /* make z-index work priority over all others */
        z-index: 100;
        
        padding: 0px 10px 0px 0px;
        border: 1px solid white;

        position: sticky;

        background: linear-gradient(
            to bottom,
            #285779,
            #285779
        );
        margin-left: auto;
        margin-right: auto;
    }
    
    header h1{
        font-size: 14px;
        font-weight: bold;
        text-justify: center;
        /* make sure the text is justified in the center */
        text-align: center;
    }
    
    header h1 span {
        letter-spacing: 3px;
    }
    
    .header-logo {
        height: 40px;
        margin-left: 20px;
    }
    
    .header-button {
        padding: 5px 5px;
        height: 30px;
        width: 60px;
        border: 2px solid white;
        background-color: #0072C6;
        color: white;
        font-size: 12px;
        font-weight: bold;
        border-radius: 53x;
        cursor: pointer;
    }
    
    .header-button:hover {
        transition: 0.2s;
        transform: translateY(-3px);
        background-color: #008ef3;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        border: 2px solid #000;
    }
    
    .header-refresh {
        font-size: 20px;
        text-justify: auto;
        height: 25px;
        width: 25px;
        margin-right: 10px;
        padding: 5px;
        background-color: #e60000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        cursor: pointer;

    }
    
    .header-refresh:hover {
        transition: 0.2s;
        font-size: 25px;
        font-weight: bold;
        transform: translateY(-3px);
        background-color: #ff0000;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
        color:rgba(0, 0, 0, 1);
        cursor: pointer;
        border: 2px solid #000;
    }

}