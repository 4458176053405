/******** CONTAINERS ********/


/* Container Fluid */
.container-fluid {
    /* takes up remaining space after header */
    flex-grow: 1;
    width: 100%;
    margin: 0;
}
  
/* Info Container */
.info-container {
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 96px); /* Set max height to viewport height minus navbar height */
    margin-top: 10px;
    margin-bottom: 10px;
    
}
  
.info-container h2 {
    font-size: 16px;
    margin-bottom: 5px;
    color: white;
    background-color:#063455;
    text-align: center;
    font-weight: bold;
    border: 2px solid white;
    margin-top: 5px;
    border-radius: 5px;
    /* keep the infocontainer h2 at the top. */
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 5px;

}
  
.info-container p {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 10px;
    
}
  
.info-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
  
.info-container li {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 5px;
}
  
  
  /* Map Container */
  
#map-container {
    height:84vh;
    margin: 20px;
    overflow: auto;
    position: relative;
    border-radius: 20px;
    border: 2px solid white;
  }
  
.detailed-container {
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: white;
    color: black;
    font-weight: bold;
}


@media (display-mode: fullscreen) {
    #map-container {
        height: 86vh;
        border: 2px solid white;
    }
}

/* Styling for small screens - Bootstrap */
@media (max-width: 991.98px) {

    .info-container {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        overflow: hidden;
        max-height: 100%;
        width: 95%;
      }
      
    .info-container h2 {
        font-size: 20px;
        margin-bottom: 10px;
        height: auto;
        width: 95%;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        position: relative;
      }
      
    .info-container p {
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 5px;
        height: auto;
        width: 95%;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
      }
      
    .info-container li {
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 3px;
        height: auto;
        width: 95%;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
      }
    
    #map-container {
        height: 60vh;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    
    }
}