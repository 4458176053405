/********** LOGIN **********/

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f0f0f0; */
}

.logo-container {
  margin-bottom: 2em;
}

.logo-container img {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.login-form {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #4fabec;
}

.login-input {
  margin-bottom: 1em;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-submit {
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
}

.login-submit:hover {
  background-color: #0056b3;
}