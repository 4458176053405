/******* System Data ********/


#system-data {

    padding: 5px;
    font-size: 10px;
    background-color: #4fabec;
    border: 2px solid white;
    border-radius: 5px;
    overflow-y: auto;
    height: 100%;
    border-bottom: 2px solid white; /* adds a bottom border to each div */
    margin-bottom: 5px;
    height: 100%;
    width: 100%;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#system-data p {

    text-align: center;
    font-size: 13px;
}
  
#system-data h3 {
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #000;

}
  
#system-data div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 2px solid #000;
    background: white;

    margin-bottom: 5px;

    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
  
#system-data div:hover {
    transform: translateY(-3px);
    background-color: #f0f8ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.info-container #system-data div {
    cursor: pointer;
}

.wind-high {
    animation: slow-flash-warning 5s infinite; /* 5s animation duration, infinite loop */

}

.temperature-high {
    animation: slow-flash-fault 5s infinite; /* 5s animation duration, infinite loop */
}

  