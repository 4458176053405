/**** Map ****/

#map {
    width: 100%;
    height: 100%;
    border: 2px solid white;

}

/* Styling for small screens - Bootstrap */
@media (max-width: 991.98px) {

    #map {
      overflow: hidden;
      max-height: 100%;
      border: 2px solid white;
      overflow: hidden;
    }
}