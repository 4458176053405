/********** LOADING **********/

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f0f0f0; */
}
  
  .spinner {

    display: flex;
    flex-direction: column;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    background-image: url('/public/img/MSPgif.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 150px;
    min-height: 150px;
    max-width: 400%;
    max-height: 400px;
    position: fixed; /* This keeps the spinner in the center of the viewport */
    top: 50%; /* Aligns the center of the element with the center of the viewport along y-axis */
    left: 50%; /* Aligns the center of the element with the center of the viewport along x-axis */
    transform: translate(-50%, -50%); /* Ensures the center of the spinner is truly in the center of the viewport */

  }
