/*********** Detailed Overview ***********/


.data-table {
    width: 100%;
    border-collapse: collapse;
}

.data-table th {
    background-color: #4fabec;
    padding: 5px;
    text-align: left;
    border: 1px solid #000;
}

.data-table td {
    padding: 5px;
    border: 1px solid black;
}

.data-table tr:last-child td {
    border-bottom: none;
}

